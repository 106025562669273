@font-face{
   font-family:Quicksand; 
   src: url(../../assets/fonts/Quicksand-Regular.ttf) format('truetype');
 }

body,
html {
  font-family: Quicksand;
}


.marker { 
  background-color: white;
  color: #1F8FFF;
  width: 20px;
  height: 20px;
  border: lightgrey 1px solid;
  border-radius: 50%;
  cursor: pointer;
  font-family: Quicksand;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.monitor_button {
  position: absolute;
  left: -5em;
  top: 19em;
  z-index: 5;
}

.mapboxgl-ctrl-group {
  border-radius: 25px;
}

.mapboxgl-ctrl-group button {
  height: 37px;
  width: 37px;
}

.mapboxgl-ctrl-top-right {
  position: absolute;
  top: 17em;
  right: 1.5em;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
  height: 100%;
  width: 100%;
}